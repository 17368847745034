<template>
  <div class="about">
    <div class="form">
      <input v-model="trackingCode" class="control" placeholder="Saisir manuellement le code de tracking"/>
      <button @click="sendTrackingCode">Valider</button>
    </div>
    <div class="reader">
      <qrcode-stream @decode="onDecode"></qrcode-stream>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      lta: null,
      trackingCode: null,
    }
  },
  methods: {
    onDecode (decodedString) {
      this.trackingCode = decodedString

      if (this.trackingCode) {
        this.sendTrackingCode()
      }
    },
    sendTrackingCode() {
      if (this.$route.query.status === "2") {
        this.lta = prompt("LTA");
        axios.post('https://ship.transmedicalservice.com/livreur/status', {
          shipCode: this.trackingCode,
          status: this.$route.query.status,
          authCode: window.localStorage.getItem('codeLivreur'),
          lta: this.lta
        }).then(response => {
          console.log(response.data);
          this.$router.push({ path: '/end' })
        }).catch(error => {
          console.log(error)
          alert("Error")
        })
      }
      else if (this.$route.query.status === "4") {
        axios.post('https://ship.transmedicalservice.com/livreur/status', {
          shipCode: this.trackingCode,
          status: this.$route.query.status,
          authCode: window.localStorage.getItem('codeLivreur'),
        }).then(response => {
          console.log(response.data);
          this.$router.push({ path: '/end' })
        }).catch(error => {
          console.log(error)
          alert("Error")
        })
      }
      else {
        this.$router.push({
          path: '/sign',
          query: {
            shipCode: this.trackingCode,
            status: this.$route.query.status,
            authCode: window.localStorage.getItem('codeLivreur'),
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  input {
    text-align: center;
    margin-bottom: 20px;

    &::placeholder {
      color: #FFF;
    }
  }

  button {
    height: 40px;
  }
}

.reader {
  width: 70%;
  margin: auto;
  margin-top: 20px;
}

::v-deep .qrcode-stream-camera {

}
</style>
